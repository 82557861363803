import React from 'react';
import Swal from 'sweetalert2'
import { API_URL, HEADERS } from '../config/constant';
function Footers() {
    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetch(API_URL+'subscribe', {
            method: 'POST',
            body: JSON.stringify({ email: e.target.elements.email.value}),
            headers:HEADERS,
        })
            .then((response) => response.json())
            .then((data) => {
                Swal.fire(
                    'Thank you for subscribe our new!',
                    'We will get back to you as soon as possible',
                    'success'
                  )
                  e.target.reset();
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    return (
        <>
            <div className="footer-dec">
                <img src="assets/images/footer-dec.png" alt="" />
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="about footer-item">
                                <div className="logo">
                                    <a href="/"><img src="assets/images/logo.png" alt="Sweit Infotech" /></a>
                                </div>
                                <a href="mailto:info@sweitinfotech.com">info@sweitinfotech.com</a>
                                <ul>
                                    <li><a name="facebook" href="https://www.facebook.com/sweitinfotech/"><i className="fa fa-facebook"></i></a></li>
                                    <li><a name="twitter" href="https://twitter.com/Raj92885058"><i className="fa fa-twitter"></i></a></li>
                                    <li><a name="instagram" href="https://www.instagram.com/sweitinfotech/"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="services footer-item">
                                <h4>Services</h4>
                                <ul>
                                    <li><a href="#services">ChatGPT development</a></li>
                                    <li><a href="#services">Socket I/O development</a></li>
                                    <li><a href="#services">PWA Development</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="community footer-item">
                                <h4>Services</h4>
                                <ul>
                                    <li><a href="#services">Web Designing & Development</a></li>
                                    <li><a href="#services">Mobile Application</a></li>
                                    <li><a href="#services">Software Development</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="subscribe-newsletters footer-item">
                                <h4>Subscribe Newsletters</h4>
                                <p>Get our latest news and ideas to your inbox</p>
                                <form  onSubmit={handleSubmit} method="post">
                                    <input type="text" name="email" id="mail" pattern="[^ @]*@[^ @]*" placeholder="Your Email" required="" />
                                    <button type="submit" name='newsletters-submit' id="newsletters-submit" className="main-button "><i className="fa fa-paper-plane-o"></i></button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="copyright">
                                <p>Copyright © 2023 Sweit Infotech. All Rights Reserved.
                                    <br />
                                    Designed by <a rel="nofollow" href="/" title="free CSS templates">Sweit Infotech</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footers;