import React from 'react';
import Swal from 'sweetalert2'
import { API_URL, HEADERS } from '../config/constant';
function Contactus() {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { mobile, email, name, website, message } = e.target.elements
        const body = { mobile: mobile.value, email: email.value, name: name.value, website: website.value, message: message.value }
        await fetch(API_URL+'contactus', {
            method: 'POST',
            body: JSON.stringify(body),
            headers:HEADERS,
        })
            .then((response) => response.json())
            .then((data) => {
                Swal.fire(
                    'Thank you for contact us!',
                    'We will get back to you as soon as possible',
                    'success'
                  )
                  e.target.reset();
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    return (
        <div id="contact" className="contact-us section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-heading">
                            <h2>Feel free to <em>Contact</em> us via the <span>fill form</span></h2>
                            <div id="map">
                                <iframe
                                    src="https://maps.google.com/maps?q=Sweit+Infotech+Kaikhali,+Biman+Nagar,+Kaikhali,+Kolkata,+West+Bengal&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0" title="This is a contact us" style={{ border: 0, height: 360, width: "100%" }} allowFullScreen=""></iframe>
                            </div>
                            <div className="info">
                                <span><i className="fa fa-phone"></i> <a href="tel:+917021600774">+91-702-160-0774</a></span>
                                <span><i className="fa fa-envelope"></i> <a
                                    href="mailto:info@sweitinfotech.com">info@sweitinfotech.com</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 align-self-center">
                        <form id="contact" onSubmit={handleSubmit} method="post">
                            <div className="row">
                                <div className="col-lg-12">
                                    <fieldset>
                                        <input type="name" name="name" id="name" placeholder="Name" autoComplete="on" required />
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <input type="text" name="mobile" id="mobile" placeholder="Mobile " autoComplete="on" required />
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email"
                                            required />
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <input type="text" name="website" id="website" placeholder="Your Website URL" />
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <textarea type="text" name="message" id="message" placeholder="Your message" cols="40"
                                            rows="5" required="" />
                                    </fieldset>
                                </div>
                                <div className="col-lg-12">
                                    <fieldset>
                                        <button type="submit" id="form-submit" name='form-submit' className="main-button">Submit Request</button>
                                    </fieldset>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="contact-dec">
                <img src="assets/images/contact-dec.png" alt="contact dec" />
            </div>
            <div className="contact-left-dec">
                <img src="assets/images/contact-left-dec.png" alt="contact left dec" />
            </div>
        </div>
    );
}
export default Contactus;