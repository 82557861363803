import React from 'react';
function AboutUs() {
    return (
        <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="left-image">
                <img src="assets/images/about-left-image.png" alt="Two Girls working together"/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-heading">
                <h2>Develop your website and <em>Software</em> &amp; <span>Mobile App</span> with us</h2>
                <p>This process is a series of steps that build upon one another, reducing risk and arriving at a final website through a structured approach.</p>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="fact-item">
                      <div className="count-area-content">
                        <div className="icon">
                          <img src="assets/images/service-icon-04.png" alt="Mobile Application"/>
                        </div>
                        <div className="count-digit">30</div>
                        <div className="count-title">Mobile App</div>
                        <p>React native, android native and  ionic framework base Application.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fact-item">
                      <div className="count-area-content">
                        <div className="icon">
                          <img src="assets/images/service-icon-02.png" alt="Websites"/>
                        </div>
                        <div className="count-digit">100</div>
                        <div className="count-title">Websites</div>
                        <p>React js, Angular, CakePHP, Laravel, Codeigniter and  HTML base web Application</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fact-item">
                      <div className="count-area-content">
                        <div className="icon">
                          <img src="assets/images/service-icon-03.png" alt="Software"/>
                        </div>
                        <div className="count-digit">80</div>
                        <div className="count-title">Software</div>
                        <p>JAVA, PHP and  NodeJs base software</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default AboutUs;