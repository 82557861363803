import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './Components/Index';
import NoPage from './Components/NoPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Index />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default App;

