import React from 'react';
import OwlCarousel from 'react-owl-carousel';
function Services() {
    const options = {
        items: 4,
        loop: true,
        dots: true,
        nav: false,
        autoplay: true,
        margin: 5,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            },
            1600: {
                items: 4
            }
        }
    }
    return (
        <div id="services" className="our-services section">
            <div className="services-right-dec">
                <img src="assets/images/services-right-dec.png" alt="services right" />
            </div>
            <div className="container">
                <div className="services-left-dec">
                    <img src="assets/images/services-left-dec.png" alt="services left" />
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-heading">
                            <h2>We <em>Provide</em> The <span>Best Service</span></h2>
                            <span>Our Services</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel className='owl-services owl-carousel owl-theme' loop margin={10} nav  {...options}>
                            <div className="item">
                                <h4>ChatGPT development</h4>
                                <div className="icon"><img src="assets/images/service-icon-01.png" alt="ChatGPT development" /></div>
                                <p>We are thrilled to announce that ChatGPT base application development available</p>
                            </div>
                            <div className="item">
                                <h4>Web Designing & Development</h4>
                                <div className="icon"><img src="assets/images/service-icon-02.png" alt="Web Designing & Development" /></div>
                                <p>Beat market competition with an interactive online presence.</p>
                            </div>
                            <div className="item">
                                <h4>Software Development</h4>
                                <div className="icon"><img src="assets/images/service-icon-03.png" alt="Software Development" /></div>
                                <p>Sweit Infotech provides end to end software development.</p>
                            </div>
                            <div className="item">
                                <h4>Mobile Application</h4>
                                <div className="icon"><img src="assets/images/service-icon-04.png" alt="Mobile Application" /></div>
                                <p>Sweit Infotech build custom app utility, game, mobile application.</p>
                            </div>
                            <div className="item">
                                <h4>PWA Development</h4>
                                <div className="icon"><img src="assets/images/service-icon-05.png" alt="PWA Development" /></div>
                                <p>A progressive web app (PWA) is an app that's built using web platform technologies</p>
                            </div>
                            <div className="item">
                                <h4>Socket I/O development</h4>
                                <div className="icon"><img src="assets/images/service-icon-06.png" alt="Socket I/O development" /></div>
                                <p>Realtime application development in socket I/O</p>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Services;