import React from 'react';
import OwlCarousel from 'react-owl-carousel';
function Portfolio() {
  const options = {
    items: 4,
    loop: true,
    dots: true,
    nav: true,
    autoplay: true,
    margin: 30,
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 3
      },
      1000: {
        items: 4
      },
      1600: {
        items: 5
      }
    }
  }
  return (
    <div id="portfolio" className="our-portfolio section">
      <div className="portfolio-left-dec">
        <img src="assets/images/portfolio-left-dec.png" alt="ur Portfolio" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading">
              <h2>Our <em>Recent</em> <span>Projects</span></h2>
              <span>Our Portfolio</span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <OwlCarousel className='owl-portfolio owl-carousel owl-theme' loop margin={10} nav  {...options}>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-01.jpg" alt="Recharge & Bill Payment" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a  target='_blank' rel="noreferrer"href="https://sweitpay.in/">
                        <h4>Sweit Pay</h4>
                      </a>
                      <span>Recharge & Bill Payment</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-02.jpg" alt="Cashvisory" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a  target='_blank' rel="noreferrer"href="https://www.cashvisory.com/">
                        <h4>Cashvisory</h4>
                      </a>
                      <span>Real financial advice to set your dreams</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-03.jpg" alt="Feonix" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a  target='_blank' rel="noreferrer"  href="https://feonix.in/">
                        <h4>Feonix</h4>
                      </a>
                      <span>Online Shopping Site For Fashion And Lifestyle</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-04.jpg" alt="GrowCap Consultancy Pvt. Ltd." />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a  target='_blank' rel="noreferrer"href="https://www.growcap.co.in/">
                        <h4>Growcap</h4>
                      </a>
                      <span>GrowCap Consultancy Pvt. Ltd.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-05.jpg" alt="Emagz" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a   target='_blank' rel="noreferrer"href="http://emagz.live/">
                        <h4>Emagz</h4>
                      </a>
                      <span>App to aggregates school and also one can manage small businesses</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="thumb">
                  <img src="assets/images/portfolio-06.jpg" alt="Shree Ram Seva" />
                  <div className="hover-effect">
                    <div className="inner-content">
                      <a  target='_blank' rel="noreferrer"href="https://srsmgwm.in/">
                        <h4>Shree Ram Seva</h4>
                      </a>
                      <span>Shree Ram Seva Mandal Gunjal Wadi(Mumbai)</span>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Portfolio;