import React from 'react';
import OwlCarousel from 'react-owl-carousel';
function Clients() {
    const options = {
        items: 5,
        loop: true,
        dots: true,
        nav: false,
        autoplay: true,
        margin: 5,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            },
            1600: {
                items: 5
            }
        }
    }
    return (
        <div id="clients" className="subscribe">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-content">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <h2>Our <span>Clients</span></h2>
                                    <OwlCarousel className='owl-clients owl-carousel owl-theme' loop margin={10} nav  {...options}>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="https://www.cashvisory.com/">
                                                <img src="assets/clients/cashvisory.png" alt="Real financial advice to set your dreams on autopilot Cashvisory" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="http://emagz.live/">
                                                <img src="assets/clients/emagz.png" alt="App to aggregates school and also one can manage small businesses." />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="https://feonix.in/">
                                                <img src="assets/clients/feonix.png" alt="Feonix.in _ Online Shopping Site For Fashion And Lifestyle" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="https://www.growcap.co.in/">
                                                <img src="assets/clients/growcap.png" alt="GrowCap Consultancy Pvt. Ltd." />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="https://sweitpay.in/">
                                                <img src="assets/clients/sweit pay.png" alt="Sweit Pay Recharge system App" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="https://srsmgwm.in/">
                                                <img src="assets/clients/srsmgwm.png" alt="Shree Ram Seva Mandal Gunjal Wadi(Mumbai)" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="http://theonlineschool.co.in/">
                                                <img src="assets/clients/theonlineschool.png" alt="the online school, school management software, services in mumbai" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="http://tsaspl.com/">
                                                <img src="assets/clients/titan.png" alt="Titan Sea & Air Services Pvt. Ltd." />
                                            </a>
                                        </div>

                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="http://www.ddjs.in/">
                                                <img src="assets/clients/deshalpur.png" alt="Shri Deshalpur (Kanthi) Deravasi Jain Sangh Shri Aath Koti Moti Paksh Jain Sangh" />
                                            </a>
                                        </div>
                                        <div className="item">
                                            <a target='_blank' rel="noreferrer" href="http://www.prissmclassNamees.com/">
                                                <img src="assets/clients/prism_edu.png" alt="Prism Educare" />
                                            </a>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Clients;