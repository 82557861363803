import React from 'react';
function Videos() {
    return (
        <div id="video" className="our-videos section">
        <div className="videos-left-dec">
          <img src="assets/images/videos-left-dec.png" alt="videos left"/>
        </div>
        <div className="videos-right-dec">
          <img src="assets/images/videos-right-dec.png" alt="videos right"/>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="naccs">
                <div className="grid">
                  <div className="row">
                    <div className="col-lg-8">
                      <ul className="nacc">
                        <li className="active">
                          <div>
                            <div className="thumb">
                              <img src="assets/images/portfolio-videos-01.jpg" alt="App to aggregates school and also one can manage small businesses"/>
                              <div className="overlay-effect">
                                <a target='_blank' rel="noreferrer" href="http://emagz.live/">
                                  <h4>Emagz</h4>
                                </a>
                                <span>App to aggregates school</span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className="thumb">
                              <img src="assets/images/portfolio-videos-02.jpg" alt="Recharge & Bill Payment"/>
                              <div className="overlay-effect">
                                <a target='_blank' rel="noreferrer" href="https://sweitpay.in/">
                                  <h4>Sweit Pay</h4>
                                </a>
                                <span>Recharge & Bill Payment</span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className="thumb">
                              <img src="assets/images/portfolio-videos-03.jpg" alt="Online Shopping Site For Fashion And Lifestyle"/>
                              <div className="overlay-effect">
                                <a target='_blank' rel="noreferrer" href="https://feonix.in/">
                                  <h4>Feonix</h4>
                                </a>
                                <span>Online Shopping Site For Fashion And Lifestyle</span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div>
                            <div className="thumb">
                              <img src="assets/images/portfolio-videos-04.jpg" alt="Cashvisory FinTech Platform Development"/>
                              <div className="overlay-effect">
                                <a target='_blank' rel="noreferrer" href="https://www.cashvisory.com/">
                                  <h4>Cashvisory</h4>
                                </a>
                                <span>Real financial advice to set your dreams</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4">
                      <div className="menu">
                        <div className="active">
                          <div className="thumb">
                            <img src="assets/images/video-thumb-01.png" alt="App to aggregates school and also one can manage small businesses"/>
                            <div className="inner-content">
                              <h4>Emagz</h4>
                              <span>App to aggregates school</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <img src="assets/images/video-thumb-02.png" alt="Recharge & Bill Payment"/>
                            <div className="inner-content">
                              <h4>Sweit Pay</h4>
                              <span>Recharge & Bill Payment</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <img src="assets/images/video-thumb-03.png" alt="Online Shopping Site For Fashion And Lifestyle"/>
                            <div className="inner-content">
                              <h4>Feonix</h4>
                              <span>ONLINE SHOPPING SITE FOR FASHION</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="thumb">
                            <img src="assets/images/video-thumb-04.png" alt="Real financial advice to set your dreams"/>
                            <div className="inner-content">
                              <h4>Cashvisory</h4>
                              <span>Real financial advice to set your dreams</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Videos;