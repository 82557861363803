import React from 'react';
import $ from 'jquery';
function Headers() {
    const clickMobileMenu = () => {
        $(this).toggleClass('active');
        $('.header-area .nav').slideToggle(200);
    }
    return (
        <>
            {/* <div id="js-preloader" className="js-preloader">
                <div className="preloader-inner">
                    <span className="dot"></span>
                    <div className="dots">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div> */}
            <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                <a href="/" className="logo">
                                    <img src="assets/images/logo.png" alt="Sweit Infotech" />
                                </a>
                                <ul className="nav">
                                    <li className="scroll-to-section"><a href="#top" className="active">Home</a></li>
                                    <li className="scroll-to-section"><a href="#services">Services</a></li>
                                    <li className="scroll-to-section"><a href="#about">About</a></li>
                                    <li className="scroll-to-section"><a href="#portfolio">Portfolio</a></li>
                                    <li className="scroll-to-section"><a href="#clients">Our Clients</a></li>
                                    <li className="scroll-to-section"><a href="#contact">Contact Us</a></li>
                                    <li className="scroll-to-section">
                                        <div className="main-red-button-hover"><a href="#contact">Contact Us Now</a></div>
                                    </li>
                                </ul>
                                <label className='menu-trigger'  onClick={clickMobileMenu}>
                                    <span>Menu</span>
                                </label>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
export default Headers;