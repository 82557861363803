import React from 'react';
import Banner from './Banner';
import Services from './Services';
import AboutUs from './AboutUs';
import Portfolio from './Portfolio';
import Videos from './Videos';
import Clients from './Clients';
import Contactus from './ContactUs';
import Footer from './Footers';
import Headers from './Headers';

function Index() {
    return (
        <>
            <Headers />
            <Banner />
            <Services />
            <AboutUs />
            <Portfolio />
            <Videos />
            <Clients />
            <Contactus />
            <Footer />
        </>
    );
}
export default Index;