import React from 'react';
import OwlCarousel from 'react-owl-carousel';

function Banner() {
    const options = {
        items: 1,
        loop: true,
        dots: true,
        nav: false,
        autoplay: true,
        margin: 0,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            },
            1600: {
                items: 1
            }
        }
    }
    return (
        <div className="main-banner" id="top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <OwlCarousel className='owl-banner owl-carousel owl-theme' loop margin={10} nav  {...options}>
                                    <div className="item header-text">
                                        <h6>Welcome to Sweit Infotech</h6>
                                        <h2>Develop <em>your Application</em> with the best <span>Performance</span>?</h2>
                                        <p>Professional are Developing your Application with various technology.</p>
                                        <div className="down-buttons">
                                            <div className="main-blue-button-hover">
                                                <a href="#contact">Message Us Now</a>
                                            </div>
                                            <div className="call-button">
                                                <a href="tel:+917021600774"><i className="fa fa-phone"></i> 702-160-0774</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item header-text">
                                        <h6>Web Development</h6>
                                        <h2>Build <em>your App</em> the best in <span>Design</span>?</h2>
                                        <p>Professional are Developing website with various technology.</p>
                                        <div className="down-buttons">
                                            <div className="main-blue-button-hover">
                                                <a href="#services">Our Services</a>
                                            </div>
                                            <div className="call-button">
                                                <a href="tel:+917021600774"><i className="fa fa-phone"></i> 702-160-0774</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item header-text">
                                        <h6>App Development</h6>
                                        <h2>Build <em>your App</em> the best in <span>Design</span>?</h2>
                                        <p>Professional are Developing your Mobile App with various technology.</p>
                                        <div className="down-buttons">
                                            <div className="main-blue-button-hover">
                                                <a href="#services">Our Services</a>
                                            </div>
                                            <div className="call-button">
                                                <a href="tel:+917021600774"><i className="fa fa-phone"></i> 702-160-0774</a>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Banner;